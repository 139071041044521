import scrollListener from '@/shared/scroll_listener.js';
import onLoadAsync from '@/shared/on_load_async.js';
import isHomepage from './homepage.js';

onLoadAsync(() => {
  // Show sub menu when clicking on a link
  document.querySelectorAll('nav.navigation-mobile .navigation-item-mobile.navigation-item-submenu').forEach((itemWithMenu) => {
    itemWithMenu.addEventListener('click', () => {
      const menuIndex = itemWithMenu.dataset.navigationMobileLinkIndex;
      const menuContent = document.querySelector(`.js_navigation_mobile_submenu_${menuIndex}`);
      menuContent.classList.add('active');
    });
  });

  // Listener for "mon espace"
  document.querySelectorAll('.js-btn-mon-espace').forEach((button) => {
    button.addEventListener('click', () => {
      const menuContent = document.querySelector('.js_navigation_mobile_submenu_mon_espace');
      menuContent.classList.add('active');
    });
  });

  // Hide menu when clicking on the "back" arrow
  document.querySelectorAll('nav .corpo-arrow_menu').forEach((closeMenuArrow) => {
    closeMenuArrow.addEventListener('click', (event) => {
      const menuIndex = closeMenuArrow.dataset.navigationMobileCloseMenuIndex;
      if (menuIndex != null) {
        // Only for submenu
        const menuContent = document.querySelector(`.js_navigation_mobile_submenu_${menuIndex}`);
        menuContent.classList.remove('active');
        event.stopPropagation();
      }
    });
  });

  if (isHomepage()) {
    // On homepage the navbar subscription button is only displayed if the subscription buttons
    // on the bloc couverture are not visible
    // (there are two possible buttons and one is used depending of the screen's width).
    // If at least one of couverture subscription buttons' bottom position is lower than the
    // navbar's bottom position the navbar button is hidden else the navbar's
    // button should be visible since it means there is no visible couverture subscription button.
    const blocCouvertureButtons = Array.from(document.querySelectorAll('.js-couverture-subscription-button'));
    const navbarSubscriptionButton = document.querySelector('.main-navbar .icons-mobile .js-btn-souscription-mobile');
    const navbar = document.querySelector('.main-navbar');

    let buttonDisplayed = true;
    const updateButton = () => {
      const navbarBottom = navbar.getBoundingClientRect().bottom;

      const highestBlocCouvertureButtonBottom = Math.max(
        ...blocCouvertureButtons.map((button) => button.getBoundingClientRect().bottom),
      );

      if (highestBlocCouvertureButtonBottom <= navbarBottom) {
        if (!buttonDisplayed) {
          navbarSubscriptionButton.classList.remove('masked');
          buttonDisplayed = true;
        }
      } else if (buttonDisplayed) {
        navbarSubscriptionButton.classList.add('masked');
        buttonDisplayed = false;
      }
    };

    scrollListener(updateButton);
    window.addEventListener('resize', updateButton);
    updateButton();
  }
});
