import onLoadAsync from '@/shared/on_load_async.js';

onLoadAsync(() => {
  document.querySelectorAll('.filtre-recherche').forEach((filtreRechercheForm) => {
    const urlPrefix = filtreRechercheForm.elements['url-prefix'].value;
    const cooperativeSelect = filtreRechercheForm.querySelector('.cooperative_select');
    const thematiqueSelect = filtreRechercheForm.querySelector('.thematique_select');

    filtreRechercheForm.addEventListener('submit', (event) => {
      event.preventDefault();

      const cooperativeValue = cooperativeSelect.value || 'toutes';

      let targetUrl;
      if (thematiqueSelect && (thematiqueSelect.value !== '')) {
        targetUrl = `${urlPrefix}/${cooperativeValue}/${thematiqueSelect.value}/1`;
      } else {
        targetUrl = `${urlPrefix}/${cooperativeValue}`;
      }
      window.location.href = targetUrl;
    });
  });
});
